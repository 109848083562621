<template>
	<div>
		<div v-if="ids.length" class="studies">
			<h3>
				These studies have been imported for {{ translate('consignerStudy') }} {{ saleEntry.patientId }} in
				{{ sale.name }}.
			</h3>
			<transition-group name="expand">
				<study-card
					v-for="studyId in ids"
					:key="studyId"
					:study="{ studyId }"
					class="study-card"
					@force-close="removeStudy({ studyId, requireConfirmation: false })"
					@close="removeStudy({ studyId })"
					@get-study="validateStudy"
				>
					<dicom-matches v-if="dicomMatches[studyId]" :matches="dicomMatches[studyId]" />
				</study-card>
			</transition-group>
			<p style="margin-bottom: 16px;">
				<ast-button type="success" @click.native="selectFromExisting">
					<span class="plus">+</span>
					Add a previously uploaded study
				</ast-button>
			</p>
		</div>
		<div v-if="canAddImages" ref="imageDrop" class="upload-section">
			<template v-if="!ids.length">
				<h3>
					Add the images you would like to submit to {{ sale.name }} for {{ translate('consignerStudy') }}
					{{ saleEntry.patientId }}.
				</h3>
				<p style="margin-bottom: 16px;">
					<ast-button type="success" @click.native="selectFromExisting">
						<span class="plus">+</span>
						Add a previously uploaded study
					</ast-button>
				</p>
			</template>
			<ast-upload-form
				v-if="canAddImages"
				class="upload-form"
				context="teleconsultation-request"
				:drop-target="imageDrop"
				:allow-dicoms="true"
				:allow-jpegs="false"
				:allow-attachments="false"
			/>
			<div v-if="imageUploads.length || excludedFiles.length" class="upload-items">
				<!-- Excluded Files -->
				<transition name="expand">
					<ol v-if="excludedFiles.length" class="excluded-files">
						<li is="UploadItemFile" v-for="(file, i) in excludedFiles" :key="i" :upload="file">
							<template slot="jpeg-error">
								This sale only accepts DICOM images; JPEG and PNG images are not allowed.
							</template>
						</li>
						<!-- Clear excluded files button -->
						<ast-button class="close" @click.native="clearExcludedFiles('teleconsultation-request')">
							<svg-icon icon="close" />
						</ast-button>
					</ol>
				</transition>
				<!-- Studies -->
				<transition-group name="expand">
					<upload-item-batch
						v-for="batchId in imageBatchIds"
						:key="batchId ? batchId : 'jpeg-form'"
						:uploads="getUploadsInBatch(batchId)"
						@remove-batch="removeBatch"
						@study-complete="addUploadedBatch"
					>
						<dicom-matches v-if="dicomMatches[batchId]" :matches="dicomMatches[batchId]" />
					</upload-item-batch>
				</transition-group>
			</div>
		</div>
		<div v-if="attachments.length" style="max-width: 1000px;" class="upload-section">
			<h3>
				{{ attachmentHeading }}
				<span v-if="!ids.length && !canAddImages">
					to {{ sale.name }} for {{ translate('consignerStudy') }} {{ saleEntry.patientId }}.
				</span>
			</h3>
			<p v-if="!ids.length && !canAddImages" style="margin-bottom: 16px;">
				<ast-button type="success" @click.native="selectFromExisting">
					<span class="plus">+</span>
					Add a previously uploaded study
				</ast-button>
			</p>
			<div class="attachments">
				<single-upload-form
					v-for="attachment in attachments"
					:key="attachment.reportTemplateImageViewId"
					class="attachment"
					context="teleconsultation-request"
					:allowed-extensions="attachment.allowedExtensions"
					:description="attachment.name"
					:image-id="attachment.imageId"
					:image-view-id="attachment.imageViewId"
					:report-template-image-view-id="attachment.reportTemplateImageViewId"
					:is-required="attachment.isRequired"
					:patient-id="attachmentPatientId"
					:study-id="attachmentStudyId"
					@remove-batch="removeBatch"
					@study-complete="addUploadedBatch"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import AstButton from '@components/Button'
import AstUploadForm from '@components/UploadForm'
import SingleUploadForm from '@components/SingleUploadForm.vue'
import StudyCard from '@components/StudyCard.vue'
import DicomMatches from '@components/view/UploadSaleDicomMatches.vue'
import { openStudiesDlg } from '@dialogs/StudiesDlg.vue'
import { openUserStudiesDlg } from '@dialogs/UserStudiesDlg.vue'
import { showAlert } from '@dialogs/MessageDlg'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import salesService from '@services/salesService'
import workflow from '@services/workflow'
import { uploadData } from '@services/uploads'
import { mapState } from 'vuex'

export default {
	name: 'TeleconsultationRequestUploadSale',
	components: {
		AstButton,
		AstUploadForm,
		DicomMatches,
		SingleUploadForm,
		StudyCard,
		UploadItemFile: () => import(/* webpackChunkName: "componentUploadItemFile" */ '@components/UploadItemFile'),
		UploadItemBatch: () => import(/* webpackChunkName: "componentUploadItemBatch" */ '@components/UploadItemBatch'),
	},
	props: {
		ids: {
			type: Array,
			required: true,
		},
		consultantId: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			batchValidationDebounce: undefined,
			haveImagesFromStudy: false,
			imageDrop: undefined,
			uploadedStudyIds: [],
			uploadedBatchIds: [],
			dicomMatches: {},
			salesService,
			uploadData,
		}
	},
	computed: {
		...mapState({
			saleHipStore: state => state.saleHipStore,
		}),
		excludedFiles() {
			return uploadData.excludedFiles.filter(file => file.context === 'teleconsultation-request')
		},
		uploads() {
			return uploadData.uploads.filter(file => file.context === 'teleconsultation-request')
		},
		saleEntryStatus() {
			return this.salesService.saleEntryStatus
		},
		sale() {
			return this.salesService.sale || {}
		},
		saleEntry() {
			return this.salesService.saleEntry || {}
		},
		areImagesRequired() {
			return (
				!this.saleEntry.allowAttachmentOnly &&
				this.saleEntry.images &&
				this.saleEntry.images.some(image => image.isRequired)
			)
		},
		canAddImages() {
			return this.saleEntry.images && this.saleEntry.images.length
		},
		imageUploads() {
			return this.uploads.filter(upload => !upload.isAttachment)
		},
		imageBatchIds() {
			return [...new Set(this.imageUploads.map(upload => upload.batchId))]
		},
		batchIds() {
			return [...new Set(this.uploads.map(upload => upload.batchId).filter(Boolean))]
		},
		studyIds() {
			const studyIds = [...this.ids, ...this.uploadedStudyIds]
			return [...new Set(studyIds)] // dedupe
		},
		isProcessing() {
			return this.batchIds.length !== this.uploadedBatchIds.length
		},
		attachments: {
			get() {
				if (!this.saleEntry.attachments) return []
				const sortIsRequiredFirst = (a, b) => {
					if (a.isRequired && !b.isRequired) return -1
					if (!a.isRequired && b.isRequired) return 1
					return 0
				}
				return this.saleHipStore.attachments.slice().sort(sortIsRequiredFirst)
			},
			set(attachments) {
				this.saleEntry.attachments = attachments
			},
		},
		requiredAttachments() {
			return this.attachments.filter(attachment => attachment.isRequired)
		},
		optionalAttachments() {
			return this.attachments.filter(attachment => !attachment.isRequired)
		},
		attachmentHeading() {
			let attachmentHeading = ''
			if (this.requiredAttachments.length) {
				attachmentHeading += 'Add the required attachment'
				if (this.requiredAttachments.length !== 1) attachmentHeading += 's'
				if (this.optionalAttachments.length) {
					attachmentHeading += ', as well as any optional attachments,'
				}
			} else if (this.optionalAttachments) {
				attachmentHeading += 'Add any optional attachments'
			}
			attachmentHeading += ' you would like to submit'
			if (this.ids.length || this.canAddImages) {
				attachmentHeading += '.'
			}
			return attachmentHeading
		},
		attachmentPatientId() {
			// sale attachments use a combination of label/session (or sale code) and hip number
			// for the patient ID, patient name, and owner name
			return [this.saleEntry.label || this.sale.code || '', this.saleEntry.patientId].join('-')
		},
		attachmentStudyId(){
		return this.ids[0]
		},
		canGoNext() {
			if (!this.sale || !this.saleEntry) return false
			// ensure there is at least one file being submitted
			if (!this.ids.length && !this.uploads.length) return false
			// if images are required, make sure user is uploading images (or has a passed-in studyId)
			const isUploadingImage = this.imageUploads && this.imageUploads.some(upload => upload.studyInstanceUid)
			// make sure required attachments are uploading or already have imageIds from study
			const isAttachmentDone = ({ imageId, reportTemplateImageViewId }) => {
				if (imageId) return true // was populated by a passed-in study
				const uploadIsForThisAttachment = upload => upload.reportTemplateImageViewId === reportTemplateImageViewId
				return this.uploads.some(uploadIsForThisAttachment)
			}
			const requiredAttachmentsArePopulated = this.requiredAttachments.every(isAttachmentDone)
			return (
				(!this.areImagesRequired || isUploadingImage || this.haveImagesFromStudy) &&
				requiredAttachmentsArePopulated &&
				!uploadData.isUploading('teleconsultation-request') &&
				!uploadData.isUploadError('teleconsultation-request') &&
				!this.isProcessing
			)
		},
	},
	watch: {
		canGoNext: {
			handler(canGoNext) {
				this.updateNextRoute()
				workflow.canGoNext = canGoNext
			},
			immediate: true,
		},
		studyIds: {
			// ensure any added studyIds get passed to next route
			handler() {
				this.updateNextRoute()
			},
			immediate: true,
		},
		imageBatchIds: {
			handler(batchIds, oldBatchIds) {
				// if no new batchIds added, do nothing
				if (batchIds.every(batchId => oldBatchIds.includes(batchId))) return
				// validate batch metadata, but debounce handler to wait for all new batchIds to be created.
				// if any additional batches take more than 300ms to get a batchId, each batch will still
				// get validated; its study date just may not make it into the error dialog
				clearTimeout(this.batchValidationDebounce)
				this.batchValidationDebounce = setTimeout(() => {
					this.validateImageBatches(batchIds)
				}, 300)
			},
			deep: true,
		},
	},
	mounted() {
		this.clearAttachmentImageIds()
		this.imageDrop = this.$refs.imageDrop
	},
	methods: {
		clearExcludedFiles(context) {
			uploadData.CLEAR_UPLOAD_EXCLUDED_FILES(context)
		},
		getUploadsInBatch(batchId) {
			return this.uploads.filter(upload => upload.batchId === batchId || (!upload.batchId && !batchId))
		},
		addUploadedBatch({ batchId, studyId, imageId, reportTemplateImageViewId }) {
			if (batchId && !this.uploadedBatchIds.includes(batchId)) {
				this.uploadedBatchIds.push(batchId)
			}
			if (studyId && !this.uploadedStudyIds.includes(studyId)) {
				this.uploadedStudyIds.push(studyId)
			}
			if (imageId && reportTemplateImageViewId) {
				const attachment = this.attachments.find(
					attachment => attachment.reportTemplateImageViewId === reportTemplateImageViewId
				)
				if (attachment) attachment.imageId = imageId
			}
		},
		async validateStudy(study) {
			// validate the study metadata, as well as whether its images/attachments are valid for the template
			const { studyUid: studyInstanceUid } = study
			const { errors, dicomMatches } = await this.validateMetadata({
				...study,
				studyInstanceUid: study.studyUid,
			})
			if (!errors.length) {
				if (study.imageData.thumbnails.length) this.$set(this.dicomMatches, study.studyId, dicomMatches)
				const canUseImages = study.imageData.thumbnails.length && this.canAddImages
				if (canUseImages) this.haveImagesFromStudy = true
				const addedAttachments = this.addAttachmentsFromStudy(study)
				// if study was usable, return
				if (canUseImages || addedAttachments) return
			}
			// if study was not usable, remove it
			const newIds = this.ids.filter(studyId => studyId !== study.studyId)
			const query = { ...this.$route.query, studyId: newIds.join(',') }
			if (!query.studyId) delete query.studyId
			this.$router.replace({ query })
			showAlert(
				errors.join(' ') ||
					'The selected study did not have any attachments or images that could be added to this entry.'
			)
		},
		async validateImageBatches(batchIds) {
			// validate the metadata for each uploading study
			if (!batchIds || !batchIds.length) return
			const createBatchValidation = batchId => {
				let batch = { batchId }
				// get metadata from first upload in batch
				batch.metadata = this.uploads.find(upload => upload.batchId === batch.batchId)
				// validate metadata against sale, and add localized study date to failed batch error
				batch.validate = new Promise(async resolve => {
					const { errors, dicomMatches } = await this.validateMetadata(batch.metadata)
					if (!errors.length) {
						this.$set(this.dicomMatches, batch.batchId, dicomMatches)
						return
					}
					const localStudyDate = this.$options.filters.localizeDate(batch.metadata.studyDateTime, {
						forceUTC: false,
						showTime: false,
					})
					batch.error = `The ${localStudyDate} study cannot be used for this entry: ` + errors.join(' ')
					resolve()
				})
				return batch
			}
			const batches = batchIds.map(createBatchValidation)
			await Promise.all(batches.map(batch => batch.validate))
			const failedBatches = batches.filter(batch => !!batch.error)
			if (!failedBatches.length) return
			failedBatches.forEach(({ batchId }) => this.removeBatch({ batchId, requireConfirmation: false }))
			// show single combined error message for all failed batches
			await showAlert(failedBatches.map(({ error }) => error).join(' '))
		},
		async validateMetadata({
			studyInstanceUid,
			acquisitionDate,
			patientId,
			patientName,
			ownerName,
			accessionNumber,
			studyDateTime,
		}) {
			// make sure study is not too old and has not been used for another entry in the same sale
			const saleEntryId = this.saleEntry.id
			const saleId = this.sale.id
			return salesService.validateMetadata({
				consultantId: this.consultantId,
				saleId,
				saleEntryId,
				studyInstanceUid,
				acquisitionDate,
				patientId,
				patientName,
				ownerName,
				accessionNumber,
				studyDateTime,
			})
		},
		clearAttachmentImageIds() {
			// clear out any previously matched attachments so they can be re-matched
			this.attachments.forEach(attachment => {
				attachment.imageId = null
			})
		},
		async revalidateStudies() {
			this.clearAttachmentImageIds()
			this.haveImagesFromStudy = false
			if (!this.ids.length) return
			try {
				// pull study data for each studyId
				workflow.isLoading = true
				const { studies } = await this.$api.viewer.getStudy({ ids: this.ids }, false)
				// validate studies
				studies.forEach(this.validateStudy)
			} finally {
				workflow.isLoading = false
			}
		},
		async removeStudy({ studyId, requireConfirmation = true }) {
			const warning = 'Are you sure you want to remove this study?'
			if (requireConfirmation && !(await showConfirm(warning))) return
			const newIds = this.ids.filter(id => id !== studyId)
			const query = { ...this.$route.query, studyId: newIds.join(',') }
			if (!query.studyId) delete query.studyId
			// update studyId query param, and revalidate remaining studies after this.ids is updated
			this.$router.replace({ query }, () => this.$nextTick(this.revalidateStudies))
		},
		async removeBatch({ batchId, studyId, reportTemplateImageViewId, requireConfirmation = true }) {
			const warning = 'Are you sure you want to remove this upload?'
			if (requireConfirmation && !(await showConfirm(warning))) return
			uploadData.stopUploads({ batchId })
			if (batchId) this.uploadedBatchIds = this.uploadedBatchIds.filter(i => i !== batchId)
			if (studyId) this.uploadedStudyIds = this.uploadedStudyIds.filter(i => i !== studyId)
			if (reportTemplateImageViewId) {
				const reportAttachment = this.attachments.find(
					attachment => attachment.reportTemplateImageViewId === reportTemplateImageViewId
				)
				reportAttachment.imageId = null
			}
		},
		addAttachmentsFromStudy(study) {
			if (!study.imageData.attachments || !study.imageData.attachments.length) return false
			let hadUsableAttachments = false
			// match up attachments to the sale template
			for (let i = 0; i < study.imageData.attachments.length; i++) {
				const attachment = study.imageData.attachments[i]
				for (let j = 0; j < this.attachments.length; j++) {
					const reportAttachment = this.attachments[j]
					const uploadIsForThisAttachment = upload =>
						upload.reportTemplateImageViewId === reportAttachment.reportTemplateImageViewId
					// skip if already attached
					if (reportAttachment.imageId || this.uploads.some(uploadIsForThisAttachment)) continue
					if (reportAttachment.imageViewId === attachment.imageViewId) {
						reportAttachment.imageId = attachment.imageId
						hadUsableAttachments = true
						break
					}
				}
			}
			return hadUsableAttachments
		},
		async selectFromExisting() {
			const openDialog = this.$store.getters.isCommunityUser ? openUserStudiesDlg : openStudiesDlg
			const study = await openDialog()
			if (!study || this.ids.includes(study.studyId)) return
			this.$router.replace({
				query: {
					...this.$route.query,
					studyId: [...this.ids, study.studyId].join(','),
				},
			})
		},
		updateNextRoute() {
			// add final list of studyIds to query
			workflow.nextRoute = {
				name: 'request-match-images',
				query: { ...this.$route.query, studyId: this.studyIds.join(',') },
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

h3 {
	margin-bottom: 20px;
}
.plus {
	font-size: 1.4em;
	padding-right: 8px;
	margin-top: -0.2em;
}
.study-card {
	margin: 15px 0;
}
.studies,
.upload-section {
	display: flex;
	flex-direction: column;
	max-width: 800px;
}
.upload-section + .upload-section {
	padding-top: 40px;
}
.upload-form {
	min-height: 200px;
}
.upload-progress {
	text-align: center;
	font-size: 1.5em;
	padding-bottom: 1em;
	font-weight: 400;
	.upload-percent {
		text-align: center;
		font-weight: 300;
	}
}
.upload-items {
	min-height: 0;
	padding-top: 30px;

	ol {
		list-style-type: none;
		position: relative;
		button.close {
			position: absolute;
			top: 0;
			right: 0;
			padding: 15px;
			background: transparent;
			border: 0;
		}
	}
}
.attachments {
	margin: 0 -16px;
	display: flex;
	flex-wrap: wrap;
	.attachment {
		margin: 16px;
	}
}
</style>
