








































import { mapState } from 'vuex'
import { showAlert } from '@dialogs/MessageDlg.vue'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import { uploadData } from '@services/uploads'
import { serializeDate } from '@services/api'

export default {
	name: 'SingleUploadForm',
	props: {
		allowedExtensions: {
			type: Array,
			required: true,
		},
		context: {
			type: String,
			default: null,
		},
		description: {
			type: String,
			required: true,
		},
		imageId: {
			type: String,
			default: null,
		},
		imageViewId: {
			type: String,
			required: true,
		},
		reportTemplateImageViewId: {
			type: String,
			required: true,
		},
		isRequired: {
			type: Boolean,
			required: true,
		},
		patientId: {
			type: String,
			required: true,
		},
		studyId: {
			type: String,
			required: false,
		},
	},
	data() {
		return {
			isDragging: false,
			status: {},
			pollInterval: undefined,
			uploadData,
		}
	},
	computed: {
		upload() {
			return this.uploadData.uploads.find(
				upload =>
					upload.reportTemplateImageViewId === this.reportTemplateImageViewId &&
					(!this.context || upload.context === this.context)
			)
		},
		acceptString() {
			if (!this.allowedExtensions.length) return '*'
			return this.allowedExtensions.map(extension => '.' + extension).join(',')
		},
		isProcessing() {
			return (
				this.upload &&
				this.upload.isUploaded &&
				!this.upload.isFailedUpload &&
				this.status.fileCount &&
				this.status.completeCount !== this.status.fileCount
			)
		},
		isComplete() {
			return (
				this.upload &&
				this.upload.isUploaded &&
				!this.upload.isFailedUpload &&
				this.status.fileCount &&
				this.status.completeCount === this.status.fileCount
			)
		},
		iconName() {
			if (this.imageId) return 'check-circle'
			if (this.upload) {
				if (this.upload.isUploading) return 'spinner'
				if (this.isProcessing) return 'spinner'
				if (this.upload.isFailedUpload) return 'exclamation-circle'
				if (this.isComplete) return 'check-circle'
				if (this.upload.isUploaded) return 'spinner'
				return 'clock-o'
			}
			if (this.isSaleDayMedicationAlert) return 'exclamation-triangle'
			if (this.isRequired) return 'file-upload'
			return 'file-upload'
		},
		iconClass() {
			if (this.imageId) return 'is-success'
			if (this.upload) {
				if (this.upload.isUploading) return 'is-muted'
				if (this.isProcessing) return 'is-muted'
				if (this.upload.isFailedUpload) return 'is-danger'
				if (this.isComplete) return 'is-success'
				if (this.upload.isUploaded) return 'is-muted'
				return 'is-muted'
			}
			if (this.isRequired) return 'is-danger'
			return 'is-muted'
		},
		isSaleDayMedicationAlert(){
			return (this.description === 'Sale Day Medication Alert')
		}
	},
	watch: {
		upload: {
			handler(upload, previousUpload) {
				if (upload && !previousUpload) {
					this.getUploadStatus()
					this.pollInterval = setInterval(this.getUploadStatus, 2000)
				}
				if (previousUpload && !upload) {
					clearInterval(this.pollInterval)
				}
			},
			immediate: true,
		},
		'status.study': function() {
			if (!this.isComplete) return
			const imageId = this.status.study.imageIds.length ? this.status.study.imageIds[0] : null
			this.$emit('study-complete', { ...this.upload, ...this.status.study, imageId })
		},
	},
	mounted() {
		this.toggleDropListeners(this.$el, true)
	},
	destroyed() {
		clearInterval(this.pollInterval)
	},
	methods: {
		async getUploadStatus() {
			if (this.upload && (this.isComplete || this.upload.isFailedUpload)) {
				clearInterval(this.pollInterval)
				return
			}
			if (this.upload && this.upload.isUploaded) {
				this.status = await uploadData.getUploadStatus(this.upload.batchId)
				if (this.status.errorCount > 0) uploadData.UPDATE_UPLOAD({ ...this.upload, isFailedUpload: true })
			}
		},
		async cancelUpload() {
			if (await showConfirm('Are you sure you want to cancel this file?')) {
				this.$emit('remove-batch', {
					...this.upload,
					...this.status.study,
					requireConfirmation: false,
				})

				this.status = {}
			}
		},
		toggleDropListeners(dropTarget, isAdding) {
			const addOrRemove = isAdding ? 'addEventListener' : 'removeEventListener'
			dropTarget[addOrRemove]('dragover', this.setIsDragging)
			dropTarget[addOrRemove]('dragenter', this.setIsDragging)
			dropTarget[addOrRemove]('dragend', this.setIsNotDragging)
			dropTarget[addOrRemove]('dragleave', this.setIsNotDragging)
			dropTarget[addOrRemove]('drop', this.dropFile)
			if (isAdding)
				this.$once('hook:beforeDestroy', () => {
					this.toggleDropListeners(dropTarget, false)
				})
		},
		toggleIsDragging(e, isDragging) {
			this.isDragging = isDragging
			e.preventDefault()
			e.stopPropagation()
		},
		setIsDragging(e) {
			this.toggleIsDragging(e, true)
		},
		setIsNotDragging(e) {
			this.toggleIsDragging(e, false)
		},
		/**
		 * Handles a change event on the file input
		 *
		 * @param {Object} e - change event
		 * @param {Object} e.target - the target element
		 */
		async selectFile({ target }) {
			if (!target.files.length) return
			await this.queueUpload(target.files)
		},
		/**
		 * Handles a drop event on the modal
		 *
		 * @param {Object} e - drop event
		 */
		async dropFile(e) {
			e.preventDefault()
			e.stopPropagation()
			this.isDragging = false
			await this.queueUpload(e.dataTransfer.files)
		},
		async queueUpload(files: FileList) {
			if (this.validateExtension(files)) {
				let study: IUploadStudy = {
					// set the patientId, patientName, and ownerName to the same value;
					// this may need be changed if this component is ever used for non-sale
					// attachments
					patientId: this.patientId,
					patientName: this.patientId,
					ownerName: this.patientId,
					accessionNumber: this.patientId,
					studyDateTime: serializeDate(new Date())
				}
				if(this.studyId){
				let studies = await this.$api.viewer.getStudy({ ids: [this.studyId] }, false)
				study.studyInstanceUid = studies.studies[0].studyUid
			}
				await uploadData.queueFilesForUpload({
					context: this.context,
					allowAttachments: true,
					files: [files[0]],
					description: this.description,
					imageViewId: this.imageViewId,
					reportTemplateImageViewId: this.reportTemplateImageViewId,
				})
				await uploadData.startUpload(this.context, study)
			} else {
				await showAlert(`This file type is not allowed for ${this.description} (${this.allowedExtensions.join(', ')}).`)
			}
		},
		validateExtension(files) {
			const getExtension = file =>
				file.name
					.split('.')
					.pop()
					.toLowerCase()
			const isAllowedExtension = file => this.allowedExtensions.includes(getExtension(file))
			// should only be one file, but check entire FileList to be safe
			// FileList is not an array, so can't do files.every or files.some
			for (let i = 0; i < files.length; i++) {
				if (!isAllowedExtension(files[i])) return false
			}
			return true
		},
	},
}
