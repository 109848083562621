<template>
	<p style="margin: 16px 0 0 40px;" :class="messageClass">
		<svg-icon :icon="icon" style="margin-right: 4px;" />
		{{ message }}
	</p>
</template>

<script>
import { formatList } from '@utils/stringUtils'
export default {
	name: 'UploadSaleDicomMatches',
	props: {
		matches: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			messageClass: '',
			icon: '',
			message: '',
		}
	},
	created() {
		const matchCount = Object.keys(this.matches).reduce((a, k) => (this.matches[k] === true ? a + 1 : a), 0)
		if (matchCount >= 2) {
			// if two identifiers matched, treat as a successful match
			this.messageClass = 'is-success'
			this.icon = 'check'
			this.message = `The DICOM tags match the selected ${this.translate('consignerStudy').toLowerCase()}.`
		} else if (matchCount === 1) {
			// if only one identifier was found, show "danger" warning because study may be for wrong hip/lot
			this.messageClass = 'is-danger'
			this.icon = 'exclamation-circle'
			let didMatch
			const didNotMatch = []
			const friendlyStrings = {
				sale: 'Sale Code',
				label: 'Label',
				hip: this.translate('consignerStudy'),
				sire: 'Sire',
				dam: 'Dam',
			}
			Object.keys(this.matches).forEach(k => {
				if (this.matches[k] === true) didMatch = friendlyStrings[k]
				else if (this.matches[k] === false) didNotMatch.push(friendlyStrings[k])
			})
			this.message = 'The ' + didMatch + ' was found in the DICOM tags'
			this.message += ', but the ' + formatList(didNotMatch) + ' were not.'
			if (this.matches.wrongHip) this.message += ' A possible mismatch was found: ' + this.matches.wrongHip + '.'
			this.message += ' However, you may still proceed with your submission.'
		} else {
			// if no identifiers found, show basic warning
			this.messageClass = 'is-warning'
			this.icon = 'exclamation-triangle'
			this.message = `We were unable to match the selected ${this.translate('consignerStudy').toLowerCase()}
			to the DICOM tags in this study.  However, you may still proceed with your submission.`
		}
	},
}
</script>
